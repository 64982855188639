// $danger-color: #f5593d;
$danger-color: #f42f54;
$success-color: #6bd098;
$default-font-color: #97afd5;
$default-color: #fff;
$dark-blue: #0a1227;

body {
  background-image: url('../../img/shapes.png');
  background-color: #101932;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: #fff !important;
}

.label-default {
  background-color: #305082 !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff !important;
  font-family: 'Quicksand';
  font-weight: 700 !important;
}

.btn-outline-default {
  color: fff !important;
}

.btn-outline-danger, .btn-danger {
  color: #fff !important;
}

label {
  letter-spacing: 0.2em;
  line-height: 1.6;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #97afd5 !important;
}

.btn-outline-default:hover {
  color: #305082 !important;
}

.navbar .navbar-brand {
  color: white !important;
}

.navbar-dark {
  background-color: $dark-blue !important;
  color: white !important;
}

.description, p, h5 {
  color: $default-font-color !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

.w-45 {
  width: 45%;
}

.profile-edit-form__button-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-edit-form__button-group button {
  width: 40%;
}

.platform-radio {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.platform-radio__item {
  width: 50px;
}

.platform-radio__item:hover {
  cursor: pointer;
  -webkit-filter: grayscale(25%);
  filter: grayscale(25%);
}

.platform-radio__item--inactive {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.platform-radio__item--active {
  // box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important;
  -webkit-box-shadow: 0px 0px 13px 12px rgba(107,208,152,0.56);
  -moz-box-shadow: 0px 0px 13px 12px rgba(107,208,152,0.56);
  box-shadow: 0px 0px 13px 12px rgba(107,208,152,0.56);
  border: 1px solid $success-color;
}

.platform-radio__item--inactive--disabled {
  pointer-events: none !important;
  -webkit-filter: grayscale(100%) blur(1px);
  filter: grayscale(100%) blur(1px);
}

.platform-radio--selected {
  border: 5px solid;
}

.platform_radio__subtitle {
  font-size: 8px !important;
}

.color-r {
  color: $danger-color;
}

.react-toast-notifications__container {
  z-index: 1050 !important;
}

input {
  margin-bottom: 2rem;
}

.fullscreen-dimmer {
  width: 100%;
  height: 100vh;
}

.disabled {
  opacity: 0.25;
}

input:disabled {
  opacity: 0.25 !important;
  pointer-events: none !important;
}

.eshop-list-item {
  padding: 1rem;
  border-radius: 6px !important;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.05);

}

.eshop-list-item:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.15);
}

.eshop-list-item__platform-icon {
  max-height: 5rem;
}

.eshop-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-register {
  background-color: transparent !important;
  box-shadow: none !important;
}

.form-control {
  border-radius: 6px !important;
  border: 1px solid #dbe4f7 !important;
  width: 100%;
  padding: 10px 15px !important;
  line-height: 21px !important;
  font-size: .9em;
  border-color: rgba(219, 228, 247, 0.25) !important;
  color: #eef3fc !important;
  background: rgba(15, 25, 50, 0.3) !important;
}

.register-form {
  border-color: rgba(219, 228, 247, 0.25) !important;
}

/* Change autofill if needed */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
//  -webkit-box-shadow: 0 0 0 30px #97afd5 inset !important;
}

.tooltip-inner {
  color: #0a1227 !important;
}

.card--glass {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 6px;
  width: 100%;
}

.fw-400 {
  font-weight: 400 !important;
}

.f-1 {
  flex: 1
}

strong {
  font-weight: bold;
}

.editable-field {
  // background-color: rgba(255, 255, 255, 0.02);
}

.editable-field:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.uppercase {
  text-transform: uppercase;
}

.text-light-blue {
  color: #97afd5 !important;
}

.component-error {
  background-color: rgba(255, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eshop-thumbnail {
  border-radius: 6px !important;
  overflow: hidden;
}

.f-0 {
  flex: 0
}